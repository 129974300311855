<app-step-bar [step]="currentPage"></app-step-bar>
<!-- <div class="tab tab8">
    <h2>Données enregistrées</h2>
    
    @if( new_saisine_abonne )
    {
        <p>Vous pouvez vous connecter à votre <a class="text-bold" href="{{lien_espace_abonne}}" target="_blank">espace personnel</a> pour consulter votre dossier.</p>
    }
    @else
    {
        <p>Votre saisine est enregistrée et le courriel de confirmation vous permettant d’activer votre espace personnel vient de vous être envoyé. Sa réception peut prendre quelques minutes.</p>
    }
    <br>
    <p>
        @if(new_saisine_abonne)
        {
            Pour toutes demande d'informations, veuillez nous laissez un message via le formulaire contact sur le site de la médiation de l'eau.
        }
        @else
        {
            En cas de non-réception de ce courriel dans les 24h, vérifier le dossier spam/indésirable de votre boite de réception et, le cas échéant, nous laisser un message via le formulaire contact du site de la Médiation de l’eau.
        }
        <br>
        <br>
        <a class="text-bold" href="assets/files/MDE_procedure_formulaire_de_contact.pdf" target="_blank" download id="procedure">La procédure pour accéder au formulaire de contact est disponible en cliquant ici</a>
    </p>
</div> -->


<div class="tab tab8">
    <h2>Données enregistrées</h2>
    
    <p>Vous pouvez vous connecter à votre <a class="text-bold" href="{{lien_espace_abonne}}" target="_blank">espace personnel</a> pour consulter votre dossier.</p>

    <br>
    <p>
        Pour toutes demande d'informations, veuillez nous laissez un message via le formulaire contact sur le site de la médiation de l'eau.

        <br>
        <br>
        <a class="text-bold" href="assets/files/MDE_procedure_formulaire_de_contact.pdf" target="_blank" download id="procedure">La procédure pour accéder au formulaire de contact est disponible en cliquant ici</a>
    </p>
</div>




