export function removeHTMLTags(strToStrip: string): string 
{
    // S'assure que la chaîne n'est pas null ou undefined et la convertit en une chaîne vide si c'est le cas.
    strToStrip = (strToStrip || "").trim()
    
    // Remplacements de chaînes spécifiques
    // strToStrip = strToStrip.replace(/<br\s*\/?>/gi, " ") // remplace les différentes variantes de <br>
    strToStrip = strToStrip.replace(/<p>&nbsp;<\/p>/gi, " ") // remplace les paragraphes contenant &nbsp;

    // Expressions régulières pour supprimer les balises <head> et autres balises HTML
    const patterns = [
        /<head[\s\S]*?>[\s\S]*<\/head>/gi, // Supprime le contenu de la balise <head>
        /<[^>]+>/g // Supprime toutes les autres balises HTML
    ]

    // Remplacement des correspondances avec des chaînes vides
    for (const pattern of patterns) 
    {
        strToStrip = strToStrip.replace(pattern, "")
    }

    // Remplacements de caractères spéciaux et nettoyage
    // strToStrip = strToStrip.replace(/\r\n/g, " ") // Remplace les retours à la ligne par des espaces
    // strToStrip = strToStrip.replace(/&nbsp;/g, " ") // Remplace les espaces insécables par des espaces
    // strToStrip = strToStrip.replace(/ {2,}/g, " ") // Remplace les multiples espaces par un seul espace

    return strToStrip.trim() // Trim final pour supprimer les espaces en début et fin de chaîne
}

