import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService 
{
  readonly MIN: number = 1
  readonly MAX: number = 7

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  currentPage!:number

  redirect(direction:number)
  {
    const url_step = this.router.url.split("/").find(str => str.startsWith("etape_"))?.split("?")[0] || "1"

    let index = parseInt(url_step.slice(-1))

    if(index >= this.MAX) index = this.MAX
    else if(index === this.MIN) index = this.MIN

    if(direction === -1 ) index--
    else if(direction === 1 ) index++

    this.currentPage = index
    this.router.navigate([`formulaire_saisine/etape_${index}`])
  }

  redirectTo(url:string)
  {
    this.router.navigate([url])
  }

  redirectToRoot()
  {
    this.router.navigate([`formulaire_saisine/etape_1`])
  }

  redirectToSite()
  {
    const link = document.createElement("a")
    link.href = environment.HOST_SITE
    link.target = "_blank"
    link.click()
  }

  getCurrentPage()
  {
    return parseInt(this.router.url.slice(-1))
  }

  getBaseRoute()
  {
    return this.document.location.origin
  }
}
