import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormDataService } from '../services/form-data/form-data.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MailService } from '../services/mail/mail.service';
import { confirmEmailAboValidator, confirmEmailValidator, sameEmailValidator } from '../custom_validators/email-validator';
import { saisine } from '../form/form.component';
import { HelpersService } from '../services/helpers/helpers.service';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CryptoService } from '../services/crypto/crypto.service';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';
import { UserService } from '../services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '../services/form/form.service';
import { InputListenerDirective } from '../directives/input-listener/input-listener.directive';


@Component({
    selector: 'app-form4',
    standalone: true,
    templateUrl: './form4.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule, InputListenerDirective]
})

export class Form4Component 
{
  form4!: FormGroup
  accountForm!: FormGroup
  isVisible: boolean = false
  currentPage!: number
  info = faInfoCircle
  exclamation = faExclamation
  isEmailExist!:boolean
  isActive!:boolean
  isEmailAboExist!:boolean
  list_lien_abonne:{ID_TYPE_REPRESENTANT:number, TYPE_REPRESENTANT:string, ORDRE:number}[] = []
  list_mandat:number[] = [11, 16, 18] //Proche (membre de la famille, ami, ...) | Représentant légal (tutelle, curatelle, ...) | Propriétaire ou locataire
  isSubmitted:boolean = false
  isAccountFormSubmitted:boolean = false
  isAccountCreation!:boolean
  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("text_limit", 300)
    .set("societe_organisme", 150)
    .set("nom", 100)
    .set("prenom", 100)
    .set("email", 200)
  )
  readonly emailPattern = Object.freeze("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$")
  abonneHasRepresentant:boolean = false
  abonne = 
  {
    id_civilite:0,
    civilite:"",
    nom:"",
    prenom:"",
    email:"",
    societe_organisme:""
  }
  

  constructor
  (
    private formDataService: FormDataService,
    private navigationService: NavigationService,
    private helperService: HelpersService,
    private mailService: MailService,
    private cryptoService: CryptoService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private formService: FormService,
  ) {}

  ngOnInit()
  {
    this.checkIfAccountCreation()

    if(this.isAccountCreation)
    {
      this.initAccountCreation()
    } 
    else
    {
      this.initForm()
      this.getDataLienAbo()
      this.currentPage = this.navigationService.getCurrentPage()
  
      this.form4.get('type_contrat')?.valueChanges.subscribe(data => {
        this.form4.get('info_eau_potable')?.setValue("")
        this.form4.get('info_assainissement_collectif')?.setValue("")
      })
    }
  }

  checkIfAccountCreation()
  {
    const data = (sessionStorage.getItem("data") as string) || ""

    if(data.trim() == "" || !data) 
    {
      this.isAccountCreation = true
    }
    else
    {
      this.cryptoService.decryptData(data).subscribe(decrypted => {
        if(data && Number.isInteger(parseInt(decrypted)))
        {
          return this.isAccountCreation = false
        }
        else
        {
          return this.isAccountCreation = true
        }
      })
    }
  }

  initAccountCreation()
  {
    this.accountForm = new FormGroup({
      civilite : new FormControl(0, [Validators.required]),
      nom : new FormControl("", [Validators.required, Validators.maxLength(this.inputLimit.get('nom'))]),
      prenom : new FormControl("", [Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))]),
      email : new FormControl("" ,[Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      email_confirm : new FormControl("", [Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      societe_organisme: new FormControl("", [Validators.maxLength(this.inputLimit.get('societe_organisme'))])
    },
    {
      validators: [confirmEmailValidator("email","email_confirm")]
    })
  }

  initForm()
  {
    this.form4 = new FormGroup({
      lien_abo : new FormControl(Number(saisine['_lien_abo'])),
      ref_dossier : new FormControl(saisine['_ref_dossier']),
      civilite : new FormControl(saisine['_civilite'], [Validators.required]),
      nom : new FormControl(saisine['_nom'], [Validators.required, Validators.maxLength(this.inputLimit.get('nom'))]),
      prenom : new FormControl(saisine['_prenom'], [Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))]),
      email : new FormControl(saisine['_email'], [Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      email_confirm : new FormControl("", [Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      societe_organisme: new FormControl(saisine['_societe_organisme'], [Validators.maxLength(this.inputLimit.get('societe_organisme'))]),
      type_contrat: new FormControl(saisine['_type_contrat'].toString(), [Validators.required, this.notZeroValidator]),
      info_eau_potable: new FormControl(saisine['_info_eau_potable'], [Validators.maxLength(this.inputLimit.get('text_limit'))]),
      info_assainissement_collectif: new FormControl(saisine['_info_assainissement_collectif'], [Validators.maxLength(this.inputLimit.get('text_limit'))]),

      isAbo: new FormControl([Validators.requiredTrue])
    })

    this.setDataAbonne(Number(saisine['_civilite']))

    const isAbo = saisine['_lien_abo'] ? "1" : "0"
    this.form4.get('isAbo')?.setValue(isAbo)
    
    if(isAbo == "1") 
    {
      this.isVisible = true
      this.form4.get("isAbo")?.updateValueAndValidity()
      this.setAboInfo()
      this.setValidators()
      this.updateValueValidity()
    }
  }

  setDataAbonne(id_civilite:number)
  {
    this.formService.getCivilite(id_civilite).subscribe(data => {
      this.abonne.civilite = data[0]?.CIVILITE
    })
    this.abonne.id_civilite = saisine['_civilite']
    this.abonne.nom = saisine['_nom']
    this.abonne.prenom = saisine['_prenom']
    this.abonne.email = saisine['_email']
    this.abonne.societe_organisme = saisine['_societe_organisme']
  }

  notZeroValidator(control: any) 
  {
    return control.value != 0 ? null : { notZero: true }
  }

  async getDataLienAbo()
  {
    (await this.helperService.getListLienAbonne()).subscribe(data => {
      for (const lien_abo of data) 
      {
        if(lien_abo.ORDRE != 2)
        {
          data.push({ID_TYPE_REPRESENTANT:-1, TYPE_REPRESENTANT:"--- Mandat / pouvoir obligatoire dans les cas ci-dessous : ---", ORDRE:1})
          break
        }
      }

      this.list_lien_abonne = data.sort((a, b) => b.ORDRE - a.ORDRE)
    })
  }

  showAboRep(event: Event)
  {
    this.isVisible = !this.isVisible

    if (event.target instanceof HTMLInputElement) 
    {
      const value = event.target?.value

      if( value == "1" )
      {
        this.setRepresentantInfo()
        this.setAboInfo()
        this.setValidators()
        this.abonneHasRepresentant = true
      }
      else
      {
        this.clearAboInfo()
        this.abonneHasRepresentant = false
      }
  
      this.updateValueValidity()
    }
  }

  setAboInfo()
  {
    this.form4.get('lien_abo')?.setValue(saisine['_lien_abo'])
    this.form4.get('ref_dossier')?.setValue(saisine['_ref_dossier'])
  }

  setRepresentantInfo()
  {
    this.form4.get('civilite')?.setValue(0)
    this.form4.get('nom')?.setValue("")
    this.form4.get('prenom')?.setValue("")
    this.form4.get('email')?.setValue("")
    this.form4.get('societe_organisme')?.setValue("")
  }

  updateValueValidity()
  {
    this.form4.get('lien_abo')?.updateValueAndValidity()
  }

  clearAboInfo()
  {
    this.form4.get('lien_abo')?.setValue(0)
    this.form4.get('ref_dossier')?.setValue("")

    saisine['_lien_abo'] = 0
    saisine['_ref_dossier'] = ""

    this.form4.get('lien_abo')?.clearValidators()
  }

  setValidators()
  {
    this.form4.get('lien_abo')?.setValidators([Validators.required])
    this.form4.get('email_confirm')?.setValidators([Validators.required])
    this.form4.setValidators(confirmEmailValidator("email","email_confirm"))
  }

  isInvalid(controlName: string, form:FormGroup)
  {
    const control = form.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  getVisibility()
  {
    return this.isVisible
  }

  eachWordUpperCase(value:string, form_element:string, form:FormGroup)
  {
    form.get(form_element)?.setValue
    (
      value
        ?.toLowerCase()
        ?.replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase())
        ?.trimStart()
    )
  }

  emptyInfoAC()
  {
    this.form4.get('info_assainissement_collectif')?.patchValue("")
  }

  emptyInfoEP()
  {
    this.form4.get('info_eau_potable')?.patchValue("")
  }

  resendMail(form:FormGroup)
  {
    if(form.get('email')?.value.trim() == "")
    {
      alert("L'adresse email est vide")
      return
    }
    if(form.get('email')?.value.trim().length > 0 && form.get('email')?.errors?.['pattern'])
    {
      alert("L'adresse email n'est pas valide")
      return
    }
    const email = form.get('email')?.value.trim()
    
    this.mailService.resendMailAccountActivation(email).subscribe(response => {
      let message

      if( response == 1 ) // OK
      {
        message = "Vous allez recevoir un mail d'activation de votre espace personnel dans quelques instants ..."
      }
      else if( response == 2 )
      {
        message = "Un problème est surevnu lors de l'envoi d'email. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau"
      }
      else if( response == 3 )
      {
        message = "L'adresse email renseignée n'existe pas ou plus"        
      }
      else
      {
        message = "Un problème est surevnu lors de l'envoi d'email. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau"
      }

      this.snackBar.open(message, "J'ai compris", {
        duration: 5000,
      })
    })
  }

  setValueSaisine()
  {
    if(this.abonneHasRepresentant)
    {
      saisine['_civilite_abo'] = Number(this.abonne.id_civilite)
      saisine['_nom_abo'] = removeHTMLTags(this.abonne.nom)
      saisine['_prenom_abo'] = removeHTMLTags(this.abonne.prenom)
      saisine['_email_abo'] = removeHTMLTags(this.abonne.email)
      saisine['_societe_organisme_abo'] = removeHTMLTags(this.abonne.societe_organisme)
    }

    saisine['_lien_abo'] = parseInt(this.form4.value.lien_abo)
    saisine['_ref_dossier'] = removeHTMLTags(this.form4.value.ref_dossier)
    saisine['_civilite'] = Number(this.form4.value.civilite)
    saisine['_nom'] = removeHTMLTags(this.form4.value.nom)
    saisine['_prenom'] = removeHTMLTags(this.form4.value.prenom)
    saisine['_email'] = removeHTMLTags(this.form4.value.email)
    saisine['_societe_organisme'] = removeHTMLTags(this.form4.value.societe_organisme)
    saisine['_type_contrat'] = this.form4.value.type_contrat
    saisine['_info_eau_potable'] = removeHTMLTags(this.form4.value.info_eau_potable)
    saisine['_info_assainissement_collectif'] = removeHTMLTags(this.form4.value.info_assainissement_collectif)
    
    this.formDataService.saveFormData(saisine)
  }

  resetStateForm(form:FormGroup)
  {
    this.isSubmitted = false
    this.isAccountFormSubmitted = false
    form.markAsPristine()
    form.markAsUntouched()
  }

  createAccount()
  {
    this.isAccountFormSubmitted = true

    if(this.accountForm.invalid)
    {
      return this.accountForm.markAllAsTouched()
    }
    else
    {
      const email = this.accountForm.get("email")?.value

      this.mailService.checkEmail(email).subscribe(data => {
        const {emailExist, isActive } = data[0]

        if(emailExist && !isActive)
        {
          this.isActive = isActive

          this.accountForm.get("email")?.setErrors({isActive:true})
          return
        }
        else if(emailExist && isActive)
        {
          this.isEmailExist = emailExist
    
          this.accountForm.get("email")?.setErrors({exists:true})
          return
        }
        else
        {
          const form= this.accountForm.value
          delete form['email_confirm']
          
          this.userService.createUser(form).subscribe(data => {
            if(Number.isInteger(data[0].ID_UTILISATEUR))
            {
              return this.navigationService.redirectTo("formulaire_saisine/creation-compte")
            }
          })
        }
      })
    }
  }

  @ScrollToTop()
  async saveFormAndNavigate(n:number)
  {
    if(this.isAccountCreation)
    {
      this.resetStateForm(this.accountForm)
    }
    else
    {
      this.resetStateForm(this.form4)
    }

    if( n === -1 ) return this.navigationService.redirect(n)
    
    this.isSubmitted = true
    
    if(this.isVisible)
    {
      if(!this.form4.get("lien_abo")?.value)
      {
        return this.form4.markAllAsTouched()
      }
    
      if(this.form4.invalid)
      {
        return this.form4.markAllAsTouched()
      }
    }
    else
    {
      if (this.form4.invalid)
      {
        this.form4.controls['civilite'].markAsTouched()
        this.form4.controls['nom'].markAsTouched()
        this.form4.controls['prenom'].markAsTouched()
        this.form4.controls['email'].markAsTouched()
        return
      }
    }
    
    // const email = this.form4.get("email")?.value

    // if(this.abonneHasRepresentant)
    // {
    //   this.mailService.checkEmail(email).subscribe(data => {
    //     const {emailExist, isActive } = data[0]
    //     if(emailExist && !isActive)
    //     {
    //       this.isActive = isActive

    //       this.form4.get("email")?.setErrors({isActive:true})
    //       return
    //     }
    //     else if(emailExist && isActive)
    //     {
    //       this.isEmailExist = emailExist

    //       this.form4.get("email")?.setErrors({exists:true})
    //       return
    //     }
    //     else
    //     {
    //       this.setValueSaisine()
    //       this.navigationService.redirect(n)
    //     }
    //   })
    // }
    // else
    // {
      this.setValueSaisine()
      this.navigationService.redirect(n)
    // }
  }
}

