import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';


@Component({
  selector: 'app-creation-compte',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './creation-compte.component.html',
  styleUrl: './creation-compte.component.css'
})
export class CreationCompteComponent 
{
  constructor(
    private navigationService: NavigationService
  ){}

  redirectToSite()
  {
    this.navigationService.redirectToSite()
  }
}
