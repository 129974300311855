import { Directive, ElementRef, Renderer2, HostListener, Optional, Self } from '@angular/core';
import { NOT_ALLOWED_CHARACTERS } from '../../helpers/characters/not-allowed-characters';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[input[type="text"], input[type="email"], input[type="password"], input[type="search"], textarea]',
  standalone: true
})
export class InputListenerDirective 
{

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
    @Optional() @Self() private ngControl: NgControl,
  ) {}

  

  @HostListener('input', ['$event'])
  onChange(event: Event)
  {
    const target = (event.target as HTMLInputElement)
    const value = target.value
    const check = this.intersects(NOT_ALLOWED_CHARACTERS, value.split(""))

    if(check)
    {
      const cleaned_value = this.removeCharacter(value, NOT_ALLOWED_CHARACTERS)

      // Si il s'agit d'un controle d'un formulaire reactive form, on met à jour la valeur du champ
      if (this.ngControl && this.ngControl.control)
      {
        this.ngControl.control.setValue(cleaned_value)
        this.ngControl.control.updateValueAndValidity()
        this.ngControl.control.markAsDirty()
        this.ngControl.control.markAsTouched()
      }
      else
      {
        (event.target as HTMLInputElement).value = cleaned_value
      }
      this.snackBar.open(`Un caractère non autorisé a été détecté, il ne sera pas pris en compte`, "J'ai compris", {
        duration: 5000,
      })
    }
  }

  // Cherche si il y a une similitude entre 2 arrays
  intersects(array_a:readonly string[] | string[], array_b:readonly string[] | string[])
  {
    const set = new Set(array_b)
    return [...new Set(array_a)].some((value) => set.has(value))
  }

  removeCharacter(str:string, charactersToRemove: readonly string[])
  {
    if(str.trim() == "" || !str) return str

    charactersToRemove.forEach((characters) => {
        str = str.replaceAll(characters, '')
    })

    return str
  }
}
