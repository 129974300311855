<div class="tab tab8">
    <h2>Données enregistrées</h2>
    
    <p>Un courriel de confirmation vous permettant d’activer votre espace personnel vient de vous être envoyé. Sa réception peut prendre quelques minutes.</p>
    
    <br>
    <p>
        En cas de non-réception de ce courriel dans les 24h, vérifier le dossier spam/indésirable de votre boite de réception et, le cas échéant, nous laisser un message via le 
        <span class="link" (click)="redirectToSite()">
            formulaire contact du site de la Médiation de l’eau.
        </span>
        
        <br>
        <br>
        <a class="cursor text-bold" href="assets/files/MDE_procedure_formulaire_de_contact.pdf" target="_blank" download id="procedure">La procédure pour accéder au formulaire de contact est disponible en cliquant ici</a>
    </p>
</div>





